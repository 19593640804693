import React, { useState } from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  Stars,
  MergeType,
  Room,
  SupervisedUserCircle,
  FindInPage,
  Settings,
  AddCircle,
} from "@material-ui/icons";
import ListItemLink from "./ListItemLink";
import Rasa from "../Rasa/Rasa.container";
import Ocena from "../Ocena/Ocena.container";
import Okrug from "../Okrug/Okrug.container";
import Vlasnik from "../Vlasnik/Vlasnik.container";
import Krmaca from "../Krmaca/Krmaca.container";
import SignUp from "../Auth/SignUp";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WithSession from "../Auth/withSession";

const drawerWidth = 350;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      width: `calc(100% - ${drawerWidth + 24}px)`,
      marginLeft: 0,
    },
  })
);

export default function Navigacija() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <ClickAwayListener onClickAway={handleDrawerClose}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <Menu />
              </IconButton>
              <Typography variant="h6" noWrap>
                Maticna evidencija svinja
              </Typography>
            </Toolbar>
          </AppBar>
        </ClickAwayListener>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItemLink to="/krmace" primary="Krmace" icon={<AddCircle />} />
            <ListItemLink
              to="/nerasti"
              primary="Nerasti"
              icon={<AddCircle />}
            />
          </List>
          <Divider />

          <List onClick={handleDrawerClose}>
            <ListItemLink
              to="/vlasnici"
              primary="Odgajivaci i vlasnici"
              icon={<SupervisedUserCircle />}
            />
            <ListItemLink
              to="/ocene"
              primary="Ocena krmace i nerasta"
              icon={<Stars />}
            />
            <ListItemLink
              to="/rase"
              primary="Rase svinja"
              icon={<MergeType />}
            />
            <ListItemLink to="/okruzi" primary="Okruzi" icon={<Room />} />
          </List>
          <Divider />

          <List>
            <ListItemLink
              to="/zahtev-za-pedigre"
              primary="Zahtev za pedigre"
              icon={<FindInPage />}
            />
            <ListItemLink
              to="/izvod-za-registraciju-grla"
              primary="Izvod za registraciju grla"
              icon={<FindInPage />}
            />
            <ListItemLink
              to="/izvod-iz-glavne-evidencije-krmaca"
              primary="Izvod iz glavne evidencije krmaca"
              icon={<FindInPage />}
            />
            <ListItemLink
              to="/izvod-iz-glavne-evidencije-nerasta"
              primary="Izvod iz glavne evidencije nerasta"
              icon={<FindInPage />}
            />
          </List>
          <Divider />

          <List>
            <ListItemLink
              to="/podesavanja"
              primary="Podesavanja"
              icon={<Settings />}
            />
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path="/">
              <WithSession />
            </Route>
            <Route path="/napravi-nalog">
              <SignUp />
            </Route>
            <Route path="/ulogovan">
              <SignUp />
            </Route>
            <Route path="/rase">
              <Rasa />
            </Route>
            <Route path="/ocene">
              <Ocena />
            </Route>
            <Route path="/vlasnici">
              <Vlasnik />
            </Route>
            <Route path="/okruzi">
              <Okrug />
            </Route>
            <Route path="/krmace">
              <Krmaca />
            </Route>
            <Route>
              <div>Jos u izradi</div>
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}
