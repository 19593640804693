import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IzmeniOcenu from "./Ocena.izmeni";
import BrisiOcenu from "./Ocena.brisi";
import gql from "graphql-tag";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ucitajOcene_ocena } from "../../generated/ucitajOcene";

export const UCITAJ_OCENE = gql`
  query ucitajOcene {
    ocena {
      _id
      opis
      napomena
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 600,
      margin: "30px auto",
    },
  })
);

type PropTypes = {
  tabela: ucitajOcene_ocena[];
};

export default function CitajOcenu(props: PropTypes) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Opis</TableCell>
            <TableCell>Napomena</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tabela.map((ocena: ucitajOcene_ocena) => {
            return (
              <TableRow key={ocena._id}>
                <TableCell>{ocena.opis}</TableCell>
                <TableCell>{ocena.napomena}</TableCell>
                <TableCell align="right">
                  <IzmeniOcenu zaIzmenu={ocena} />
                  <BrisiOcenu id={ocena._id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
