import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IzmeniVlasnika from "./Vlasnik.izmeni";
import BrisiVlasnika from "./Vlasnik.brisi";
import gql from "graphql-tag";
import { grey, green } from "@material-ui/core/colors";
import { CheckCircle, Cancel } from "@material-ui/icons/";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

export const UCITAJ_VLASNIKE = gql`
  query ucitajVlasnike {
    vlasnik {
      _id
      sifra
      ime
      prezime
      mesto
      fiksniTelefon
      mobilniTelefon
      postanskiBroj
      kontaktMesto
      adresa
      gazdinstvoSifra
      gazdinstvoNaziv
      gazdinstvoStatus
      napomena
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 1200,
      margin: "30px auto",
      display: "flex",
      flexWrap: "wrap"
    },
    root: {
      width: 390,
      margin: 5,
      textAlign: "left",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column"
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: green[500]
    },
    avatarDisable: {
      backgroundColor: grey[300]
    },
    content: {
      textAlign: "left",
      overflowX: "auto"
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "flex-end "
    },
    napomena: {
      padding: " 0 0 11px 11px",
      fontStyle: "italic",
      color: grey[800]
    }
  })
);

type PropTypes = {
  brisiVlasnika: any;
  setTabela: any;
  tabela: any;
  izmeniVlasnika: any;
};

export default function CitajVlasnika(props: PropTypes) {
  const classes = useStyles();
  if (props.tabela.vlasnik.length === 0) {
    return <>Nema unetih podataka</>;
  }
  return (
    <div className={classes.table}>
      {props.tabela.vlasnik.map((vlasnik: any) => {
        return (
          <Card className={classes.root} key={vlasnik.sifra}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  className={
                    vlasnik.gazdinstvoStatus === "Aktivno"
                      ? classes.avatar
                      : classes.avatarDisable
                  }
                >
                  {vlasnik.gazdinstvoStatus === "Aktivno" ? (
                    <CheckCircle />
                  ) : (
                    <Cancel />
                  )}
                </Avatar>
              }
              title={`${vlasnik.ime} ${vlasnik.prezime} (${vlasnik.mesto})`}
              subheader={`Sifra: ${vlasnik.sifra}`}
            />
            <CardContent className={classes.content}>
              <Table>
                <TableBody>
                  {vlasnik.fiksniTelefon && (
                    <TableRow>
                      <TableCell padding="none">Fiksni:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.fiksniTelefon}
                      </TableCell>
                    </TableRow>
                  )}
                  {vlasnik.mobilniTelefon && (
                    <TableRow>
                      <TableCell padding="none">Mobilni:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.mobilniTelefon}
                      </TableCell>
                    </TableRow>
                  )}
                  {vlasnik.postanskiBroj && (
                    <TableRow>
                      <TableCell padding="none">Post broj:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.postanskiBroj}
                      </TableCell>
                    </TableRow>
                  )}
                  {vlasnik.kontaktMesto && (
                    <TableRow>
                      <TableCell padding="none">Mesto:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.kontaktMesto}
                      </TableCell>
                    </TableRow>
                  )}
                  {vlasnik.adresa && (
                    <TableRow>
                      <TableCell padding="none">Adresa:</TableCell>
                      <TableCell padding="none">{vlasnik.adresa}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
            <CardContent className={classes.content}>
              <Table>
                <TableBody>
                  {vlasnik.gazdinstvoNaziv && (
                    <TableRow>
                      <TableCell padding="none">Gazdinstvo:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.gazdinstvoNaziv}
                      </TableCell>
                    </TableRow>
                  )}
                  {vlasnik.gazdinstvoSifra && (
                    <TableRow>
                      <TableCell padding="none">Sifra:</TableCell>
                      <TableCell padding="none">
                        {vlasnik.gazdinstvoSifra}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
            <CardActions className={classes.actions} disableSpacing>
              <div className={classes.napomena}>
                {vlasnik.napomena && <div>{vlasnik.napomena}</div>}
              </div>
              <div style={{ display: "flex" }}>
                <IzmeniVlasnika
                  setTabela={props.setTabela}
                  tabela={props.tabela}
                  vlasnik={vlasnik}
                  izmeniVlasnika={props.izmeniVlasnika}
                />
                <BrisiVlasnika
                  brisiVlasnika={props.brisiVlasnika}
                  setTabela={props.setTabela}
                  tabela={props.tabela}
                  vlasnik={vlasnik}
                />
              </div>
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
}
