import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Navigacija from "../Navigation/Navigation";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import "./App.css";
import { setCurrentUser } from "../../actions";
import { useDispatch } from "react-redux";
import gql from "graphql-tag";

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      _id
      sifra
    }
  }
`;

function App() {
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(GET_CURRENT_USER);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error.message} />;
  }

  if (data) {
    if (data.getCurrentUser) {
      dispatch(setCurrentUser({ ...data.getCurrentUser }));
    } else {
      dispatch(setCurrentUser({}));
    }
  }
  return (
    <div className="App">
      <Navigacija />
    </div>
  );
}

export default App;
