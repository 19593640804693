import React, { useState, ChangeEvent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";

import { UCITAJ_OCENE } from "../Ocena/Ocena.citaj";
import { UCITAJ_RASE } from "../Rasa/Rasa.citaj";
import { UCITAJ_VLASNIKE } from "../Vlasnik/Vlasnik.citaj";
import { UCITAJ_OKRUGE } from "../Okrug/Okrug.citaj";

import gql from "graphql-tag";
import { UCITAJ_KRMACE } from "./Krmaca.citaj";

export const DODAJ_KRMACU = gql`
  mutation dodajKrmacu($krmacaInput: KrmacaInput) {
    dodajKrmacu(krmacaInput: $krmacaInput) {
      idKrmace
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      margin: "auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 600,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export default function DodajKrmacu() {
  const resetKrmace = {
    idKrmace: "",
    mbr: "",
    selekcijskiBroj: "",
    hbrb: "",
    rasa: null,
    opis: null,
    datumRodjenja: "",
    iskljucenje: "",
    uzrok: "",
    ocena: null,
    okrug: null,
    odgajivac: null,
    vlasnik: null,
    napomena: "",
    otac: null,
    majka: null,
  };

  const classes = useStyles();
  const [dodajKrmacu] = useMutation(DODAJ_KRMACU);
  const okruziData = useQuery(UCITAJ_OKRUGE);
  const krmaceData = useQuery(UCITAJ_KRMACE);
  const oceneData = useQuery(UCITAJ_OCENE);
  const raseData = useQuery(UCITAJ_RASE);
  const vlasniciData = useQuery(UCITAJ_VLASNIKE);
  const [buduciPodaci, setBuduciPodaci] = useState(resetKrmace);

  function dodajPodatkeKrmace(event: React.FormEvent) {
    event.preventDefault();
    dodajKrmacu({
      variables: {
        krmacaInput: buduciPodaci,
      },
      refetchQueries: ["ucitajKrmace"],
    });
    setBuduciPodaci(resetKrmace);
  }

  // TODO: Dodaj i ostala obavezna polja za validaciju
  const onemogucenoDugme = buduciPodaci.idKrmace === "";

  if (
    okruziData.loading ||
    oceneData.loading ||
    vlasniciData.loading ||
    raseData.loading ||
    krmaceData.loading
  ) {
    return <Loading />;
  }
  if (
    okruziData.error ||
    oceneData.error ||
    vlasniciData.error ||
    raseData.error ||
    krmaceData.error
  ) {
    return <Error error={"Nesto se nije lepo ucitalo"} />;
  }

  const rase = raseData.data.rasa;
  const okruzi = okruziData.data.okrug;
  const ocene = oceneData.data.ocena;
  const vlasnici = vlasniciData.data.vlasnik;
  const krmace = krmaceData.data.krmaca;

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        dodajPodatkeKrmace(e);
      }}
    >
      <div>
        <TextField
          required
          id="idKrmace"
          label="ID"
          variant="outlined"
          value={buduciPodaci.idKrmace}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBuduciPodaci({ ...buduciPodaci, idKrmace: e.target.value })
          }
        />
        <TextField
          id="mbr"
          label="MBR"
          variant="outlined"
          fullWidth
          value={buduciPodaci.mbr}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBuduciPodaci({ ...buduciPodaci, mbr: e.target.value })
          }
        />
        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-rasu"
            id="rasa"
            value={buduciPodaci.rasa === null ? "naslov" : buduciPodaci.rasa}
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                rasa: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi rasu
            </MenuItem>
            {rase.map((rasa: any) => {
              return (
                <MenuItem value={rasa._id} key={rasa._id}>
                  {rasa.opis}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj rasu</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-ocenu"
            id="ocena"
            value={buduciPodaci.ocena === null ? "naslov" : buduciPodaci.ocena}
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                ocena: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi ocenu
            </MenuItem>
            {ocene.map((ocena: any) => {
              return (
                <MenuItem value={ocena._id} key={ocena._id}>
                  {ocena.opis}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj ocenu</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-ocenu"
            id="okrug"
            value={buduciPodaci.okrug === null ? "naslov" : buduciPodaci.okrug}
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                okrug: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi okrug
            </MenuItem>
            {okruzi.map((okrug: any) => {
              return (
                <MenuItem value={okrug._id} key={okrug._id}>
                  {okrug.naziv}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj okrug</FormHelperText>
        </FormControl>

        {/* TODO: Sakrij ovo polje i posalji _id trenutnog korisnika */}
        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-vlasnika"
            id="vlasnik"
            value={
              buduciPodaci.vlasnik === null ? "naslov" : buduciPodaci.vlasnik
            }
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                vlasnik: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi vlasnika
            </MenuItem>
            {vlasnici.map((vlasnik: any) => {
              return (
                <MenuItem value={vlasnik._id} key={vlasnik._id}>
                  {vlasnik.ime} {vlasnik.prezime}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj vlasnika</FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-odgajivaca"
            id="odgajivac"
            value={
              buduciPodaci.odgajivac === null
                ? "naslov"
                : buduciPodaci.odgajivac
            }
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                odgajivac: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi odgajivaca
            </MenuItem>
            {vlasnici.map((odgajivac: any) => {
              return (
                <MenuItem value={odgajivac._id} key={odgajivac._id}>
                  {odgajivac.ime} {odgajivac.prezime}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj odgajivaca</FormHelperText>
        </FormControl>

        {/* TODO: dodaj search on type za majku, a posle i za oca */}
        <FormControl className={classes.formControl}>
          <Select
            labelId="izaberi-krmacu"
            id="krmaca"
            value={buduciPodaci.majka === null ? "naslov" : buduciPodaci.majka}
            variant="outlined"
            onChange={(e: any) => {
              setBuduciPodaci({
                ...buduciPodaci,
                majka: e.target.value,
              });
            }}
          >
            <MenuItem value="naslov" disabled>
              Izaberi krmacu
            </MenuItem>
            {krmace.map((krmaca: any) => {
              return (
                <MenuItem value={krmaca._id} key={krmaca._id}>
                  {krmaca._id}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Dodaj krmacu</FormHelperText>
        </FormControl>
        {/* TODO:  Dodaj oca */}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={onemogucenoDugme}
        >
          Dodaj Krmacu
        </Button>
      </div>
    </form>
  );
}
