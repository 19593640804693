const SET_CURRENT_USER = "SET_CURRENT_USER";

function setCurrentUser(user: any) {
  return {
    type: SET_CURRENT_USER,
    payload: {
      currentUser: user,
    },
  };
}
export { setCurrentUser, SET_CURRENT_USER };
