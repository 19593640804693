import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IzmeniOkrug from "./Okrug.izmeni";
import BrisiOkrug from "./Okrug.brisi";
import gql from "graphql-tag";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ucitajOkruge_okrug } from "../../generated/ucitajOkruge";

export const UCITAJ_OKRUGE = gql`
  query ucitajOkruge {
    okrug {
      _id
      naziv
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 600,
      margin: "30px auto",
    },
  })
);

type PropTypes = {
  tabela: ucitajOkruge_okrug[];
};

export default function CitajOkrug(props: PropTypes) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Opis</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tabela.map((okrug: ucitajOkruge_okrug) => {
            return (
              <TableRow key={okrug._id}>
                <TableCell>{okrug.naziv}</TableCell>
                <TableCell align="right">
                  <IzmeniOkrug zaIzmenu={okrug} />
                  <BrisiOkrug id={okrug._id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
