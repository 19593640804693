import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  CardContent,
  Card,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

import gql from "graphql-tag";

export const SIGNUP_USER = gql`
  mutation signupUser($vlasnikInput: VlasnikInput) {
    signupUser(vlasnikInput: $vlasnikInput) {
      token
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      margin: "20px auto",
      "& .MuiTextField-root": {
        margin: "auto",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    form: {
      width: 600,
      margin: "auto",
    },
    button: {
      margin: theme.spacing(1),
    },
    title: {
      fontSize: 14,
      textAlign: "left",
    },
    dropdown: {
      width: "100%",
      paddingTop: 8,
    },
    selectBox: {
      "& .MuiSelect-selectMenu": {
        padding: "10.5px 0px",
      },
    },
  })
);

export default function SignUp() {
  let history = useHistory();
  const resetUsera = {
    sifra: "",
    password: "",
    passwordConfirmation: "",
    ime: "",
    prezime: "",
    mesto: "",
    fiksniTelefon: "",
    mobilniTelefon: "",
    postanskiBroj: "",
    kontaktMesto: "",
    adresa: "",
    gazdinstvoSifra: "",
    gazdinstvoNaziv: "",
    gazdinstvoStatus: "Aktivno",
    napomena: "",
    aktivan: true,
  };

  const classes = useStyles();
  const [singupUser, { error }] = useMutation(SIGNUP_USER);
  const [buduciPodaci, setBuduciPodaci] = useState(resetUsera);
  const [kreiranNalog, setKreiranNalog] = useState(false);

  function izmeniUnos(event: any) {
    const { id, value } = event.target;
    setBuduciPodaci({ ...buduciPodaci, [id]: value });
  }

  function izmeniUnosgazdinstvoStatus(event: any) {
    const { value } = event.target;
    setBuduciPodaci({ ...buduciPodaci, gazdinstvoStatus: value });
  }

  function dodajPodatkeUsera(event: React.FormEvent) {
    event.preventDefault();
    delete buduciPodaci.passwordConfirmation;
    singupUser({
      variables: {
        vlasnikInput: buduciPodaci,
      },
    })
      .then(() => {
        localStorage.setItem("token", "");
        setKreiranNalog(true);
      })
      .catch((err: any) => {
        console.log(err);
        return null;
      });
  }

  function onemogucenoDugme() {
    const { sifra, password, passwordConfirmation } = buduciPodaci;

    if (sifra === "" || password === "" || password !== passwordConfirmation) {
      return true;
    }
    return false;
  }
  if (kreiranNalog === true) {
    return (
      <>
        Uspesno ste kreirali nalog
        <br />
        <Button
          onClick={() => {
            history.push("/");
          }}
        >
          Uloguj se
        </Button>
      </>
    );
  }

  return (
    <>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={(e: React.FormEvent) => {
          dodajPodatkeUsera(e);
        }}
      >
        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Opsti podaci
            </Typography>
            <TextField
              required
              fullWidth
              id="sifra"
              label="Sifra"
              variant="outlined"
              value={buduciPodaci.sifra}
              onInput={(e) => izmeniUnos(e)}
            />
            <TextField
              fullWidth
              id="password"
              label="Lozinka"
              variant="outlined"
              type="password"
              value={buduciPodaci.password}
              onInput={(e) => izmeniUnos(e)}
            />
            <TextField
              fullWidth
              id="passwordConfirmation"
              label="Lozinka jos jednom"
              variant="outlined"
              type="password"
              value={buduciPodaci.passwordConfirmation}
              onInput={(e) => izmeniUnos(e)}
            />
            <TextField
              fullWidth
              required
              id="ime"
              label="Ime"
              variant="outlined"
              value={buduciPodaci.ime}
              onInput={(e) => izmeniUnos(e)}
            />
            <TextField
              fullWidth
              required
              id="prezime"
              label="Prezime"
              variant="outlined"
              value={buduciPodaci.prezime}
              onInput={(e) => izmeniUnos(e)}
            />
            <TextField
              fullWidth
              id="mesto"
              label="Mesto"
              variant="outlined"
              value={buduciPodaci.mesto}
              onInput={(e) => izmeniUnos(e)}
            />
          </CardContent>
        </Card>
        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Kontakt
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="fiksniTelefon"
                  label="Fiksni telefon"
                  variant="outlined"
                  value={buduciPodaci.fiksniTelefon}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="mobilniTelefon"
                  label="Mobilni telefon"
                  variant="outlined"
                  value={buduciPodaci.mobilniTelefon}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="postanskiBroj"
                  label="Postanski broj"
                  variant="outlined"
                  value={buduciPodaci.postanskiBroj}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="kontaktMesto"
                  label="Mesto"
                  variant="outlined"
                  value={buduciPodaci.kontaktMesto}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="adresa"
                  label="Adresa"
                  variant="outlined"
                  value={buduciPodaci.adresa}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Gazdinstvo
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="gazdinstvoSifra"
                  label="Sifra gazdinstva"
                  variant="outlined"
                  value={buduciPodaci.gazdinstvoSifra}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  id="gazdinstvoNaziv"
                  label="Naziv gazdinstva"
                  variant="outlined"
                  value={buduciPodaci.gazdinstvoNaziv}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.dropdown}>
                  <Select
                    id="gazdinstvoStatus"
                    value={buduciPodaci.gazdinstvoStatus}
                    variant="outlined"
                    // @ts-ignore
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      izmeniUnosgazdinstvoStatus(e);
                    }}
                    className={classes.selectBox}
                  >
                    <MenuItem value={"Aktivno"}>Aktivno</MenuItem>
                    <MenuItem value={"Neaktivno"}>Neaktivno</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="napomena"
                  label="Napomena"
                  variant="outlined"
                  multiline
                  value={buduciPodaci.napomena}
                  onInput={(e) => izmeniUnos(e)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={onemogucenoDugme()}
        >
          Napravi nalog
        </Button>
        {error && <>{error.message}</>}
      </form>
    </>
  );
}
