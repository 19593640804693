import React from "react";
import { PropTypes } from "@material-ui/core";

type PropTypes = {
  error: String;
};
const Error = (props: PropTypes) => {
  return <>`Error! ${props.error}`</>;
};

export default Error;
