import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import DodajVlasnika from "./Vlasnik.dodaj";
import CitajVlasnika from "./Vlasnik.citaj";

import { ucitajVlasnike } from "../../generated/ucitajVlasnike";

import { DODAJ_VLASNIKA } from "./Vlasnik.dodaj";
import { UCITAJ_VLASNIKE } from "./Vlasnik.citaj";
import { IZMENI_VLASNIKA } from "./Vlasnik.izmeni";
import { BRISI_VLASNIKA } from "./Vlasnik.brisi";

export default function Vlasnik() {
  const { loading, error, data } = useQuery<ucitajVlasnike>(UCITAJ_VLASNIKE);

  const [tabela, setTabela] = useState<any>([]);

  const [dodajVlasnika] = useMutation(DODAJ_VLASNIKA);
  const [brisiVlasnika] = useMutation(BRISI_VLASNIKA);
  const [izmeniVlasnika] = useMutation(IZMENI_VLASNIKA);
  const [podaci, setPodaci] = useState({
    sifra: "",
    ime: "",
    prezime: "",
    mesto: null,
    fiksniTelefon: null,
    mobilniTelefon: null,
    postanskiBroj: null,
    kontaktMesto: null,
    adresa: null,
    gazdinstvoSifra: null,
    gazdinstvoNaziv: null,
    gazdinstvoStatus: "Aktivno",
    napomena: null
  });

  useEffect(() => {
    if (!loading) {
      setTabela(data);
    }
  }, [loading, data]);

  if (loading) {
    return <>Loading</>;
  }
  if (error) {
    return <>`Error! ${error.message}`</>;
  }

  if (tabela.length === 0) {
    return <>Load</>;
  }

  return (
    <>
      <Typography variant="h6" noWrap>
        Odgajivaci i vlasnici
      </Typography>
      <DodajVlasnika
        dodajVlasnika={dodajVlasnika}
        podaci={podaci}
        setPodaci={setPodaci}
        tabela={tabela}
        setTabela={setTabela}
      />
      <CitajVlasnika
        setTabela={setTabela}
        tabela={tabela}
        brisiVlasnika={brisiVlasnika}
        izmeniVlasnika={izmeniVlasnika}
      />
    </>
  );
}
