import React from "react";
import { Button } from "@material-ui/core";
import SignIn from "./SignIn";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../../actions";

export default function WithSession({ children }: any) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state.currentUser);

  function signOut() {
    localStorage.setItem("token", "");
    dispatch(setCurrentUser({}));
  }

  return (
    <>
      {currentUser && currentUser.sifra ? (
        <>
          Ulogovani ste kao {currentUser.sifra}
          <Button onClick={() => signOut()}>Izloguj se</Button>
        </>
      ) : (
        <SignIn />
      )}
      {children}
    </>
  );
}
