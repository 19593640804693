import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "@material-ui/core";
import gql from "graphql-tag";
import { ucitajVlasnike_vlasnik } from "../../generated/ucitajVlasnike";

export const BRISI_VLASNIKA = gql`
  mutation brisiVlasnika($brisiVlasnikaInput: BrisiVlasnikaInput) {
    brisiVlasnika(brisiVlasnikaInput: $brisiVlasnikaInput) {
      mesto
    }
  }
`;

type PropTypes = {
  setTabela: any;
  tabela: any;
  brisiVlasnika: any;
  vlasnik: any;
};

export default function BrisiVlasnika(props: PropTypes) {
  function brisiVlasnikaSaListe(vlasnik: any) {
    const { sifra, ime, prezime } = vlasnik;
    props.brisiVlasnika({
      variables: {
        brisiVlasnikaInput: { sifra, ime, prezime }
      }
    });
    props.setTabela({
      vlasnik: props.tabela.vlasnik.filter(
        (vlasnik: ucitajVlasnike_vlasnik) => !(vlasnik.sifra === sifra)
      )
    });
  }
  return (
    <IconButton
      onClick={() => {
        brisiVlasnikaSaListe(props.vlasnik);
      }}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
}
