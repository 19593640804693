import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

import gql from "graphql-tag";

export const SIGNIN_USER = gql`
  mutation signinUser($sifra: String!, $password: String!) {
    signinUser(sifra: $sifra, password: $password) {
      token
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      margin: "auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 600,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export default function SignIn() {
  let history = useHistory();
  const resetKorisnika = {
    sifra: "",
    password: "",
  };

  const classes = useStyles();
  const [singinUser, { error }] = useMutation(SIGNIN_USER);
  const [buduciPodaci, setBuduciPodaci] = useState(resetKorisnika);

  function izmeniUnos(event: any) {
    const { id, value } = event.target;
    setBuduciPodaci({ ...buduciPodaci, [id]: value });
  }

  function dodajPodatkeOcene(event: React.FormEvent) {
    event.preventDefault();
    singinUser({
      variables: {
        ...buduciPodaci,
      },
    })
      .then(({ data }) => {
        localStorage.setItem("token", data.signinUser.token);
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        return null;
      });
  }

  function onemogucenoDugme() {
    const { sifra, password } = buduciPodaci;

    if (sifra === "" || password === "") {
      return true;
    }
    return false;
  }

  return (
    <>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={(e: React.FormEvent) => {
          dodajPodatkeOcene(e);
        }}
      >
        <div>
          <TextField
            required
            id="sifra"
            label="Sifra Vlasnika"
            variant="outlined"
            value={buduciPodaci.sifra}
            onInput={(e) => izmeniUnos(e)}
          />
          <TextField
            id="password"
            label="Lozinka"
            variant="outlined"
            type="password"
            rows="4"
            value={buduciPodaci.password}
            onInput={(e) => izmeniUnos(e)}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
            disabled={onemogucenoDugme()}
          >
            Uloguj se
          </Button>
          {error && <>{error.message}</>}
        </div>
      </form>
      <Button onClick={() => history.push("/napravi-nalog")}>
        Napravi nalog
      </Button>
    </>
  );
}
