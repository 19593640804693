import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import gql from "graphql-tag";

export const DODAJ_OCENU = gql`
  mutation dodajOcenu($ocenaInput: OcenaInput) {
    dodajOcenu(ocenaInput: $ocenaInput) {
      opis
      napomena
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      margin: "auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 600,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export default function DodajOcenu() {
  const resetOcene = {
    opis: "",
    napomena: "",
  };

  const classes = useStyles();
  const [dodajOcenu] = useMutation(DODAJ_OCENU);
  const [buduciPodaci, setBuduciPodaci] = useState(resetOcene);

  function dodajPodatkeOcene(event: React.FormEvent) {
    event.preventDefault();
    dodajOcenu({
      variables: {
        ocenaInput: buduciPodaci,
      },
      refetchQueries: ["ucitajOcene"],
    });
    setBuduciPodaci(resetOcene);
  }

  const onemogucenoDugme = buduciPodaci.opis === "";

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        dodajPodatkeOcene(e);
      }}
    >
      <div>
        <TextField
          required
          id="opis"
          label="Opis"
          variant="outlined"
          value={buduciPodaci.opis}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBuduciPodaci({ ...buduciPodaci, opis: e.target.value })
          }
        />
        <TextField
          id="napomena"
          label="Napomena"
          variant="outlined"
          fullWidth
          multiline
          rows="4"
          value={buduciPodaci.napomena}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBuduciPodaci({ ...buduciPodaci, napomena: e.target.value })
          }
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={onemogucenoDugme}
        >
          Dodaj Ocenu
        </Button>
      </div>
    </form>
  );
}
