import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import NemaPodataka from "../NemaPodataka/NemaPodataka";

import DodajKrmacu from "./Krmaca.dodaj";
import CitajKrmacu from "./Krmaca.citaj";

import { UCITAJ_KRMACE } from "./Krmaca.citaj";

export default function Krmaca() {
  const { loading, error, data } = useQuery(UCITAJ_KRMACE);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <>
      <Typography variant="h6" noWrap>
        Krmace
      </Typography>
      <DodajKrmacu />
      {data.krmaca.length === 0 ? (
        <NemaPodataka />
      ) : (
        <CitajKrmacu tabela={data.krmaca} />
      )}
    </>
  );
}
