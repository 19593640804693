import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

export const BRISI_KRMACU = gql`
  mutation brisiKrmacu($_id: ID) {
    brisiKrmacu(_id: $_id) {
      _id
    }
  }
`;

type PropTypes = {
  id: string;
};

export default function BrisiKrmacu(props: PropTypes) {
  const [brisiKrmacu] = useMutation(BRISI_KRMACU);

  return (
    <IconButton
      onClick={() => {
        brisiKrmacu({
          variables: { _id: props.id },
          refetchQueries: ["ucitajKrmace"],
        });
      }}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
}
