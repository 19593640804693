import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IzmeniRasu from "./Rasa.izmeni";
import BrisiRasu from "./Rasa.brisi";
import gql from "graphql-tag";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ucitajRase_rasa } from "../../generated/ucitajRase";

export const UCITAJ_RASE = gql`
  query ucitajRase {
    rasa {
      _id
      opis
      napomena
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 600,
      margin: "30px auto",
    },
  })
);

type PropTypes = {
  tabela: ucitajRase_rasa[];
};

export default function CitajRasu(props: PropTypes) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Opis</TableCell>
            <TableCell>Napomena</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tabela.map((rasa: ucitajRase_rasa) => {
            return (
              <TableRow key={rasa._id}>
                <TableCell>{rasa.opis}</TableCell>
                <TableCell>{rasa.napomena}</TableCell>
                <TableCell align="right">
                  <IzmeniRasu zaIzmenu={rasa} />
                  <BrisiRasu id={rasa._id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
