import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

export const BRISI_OKRUG = gql`
  mutation brisiOkrug($_id: ID) {
    brisiOkrug(_id: $_id) {
      _id
    }
  }
`;

type PropTypes = {
  id: string;
};

export default function BrisiOkrug(props: PropTypes) {
  const [brisiOkrug] = useMutation(BRISI_OKRUG);

  return (
    <IconButton
      onClick={() => {
        brisiOkrug({
          variables: { _id: props.id },
          refetchQueries: ["ucitajOkruge"],
        });
      }}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
}
