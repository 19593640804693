import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Button,
  TextField,
  PropTypes,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import gql from "graphql-tag";
import { ucitajVlasnike_vlasnik } from "../../generated/ucitajVlasnike";

import EditIcon from "@material-ui/icons/Edit";

export const IZMENI_VLASNIKA = gql`
  mutation izmeniVlasnika($izmeniVlasnikaInput: IzmeniVlasnikaInput) {
    izmeniVlasnika(izmeniVlasnikaInput: $izmeniVlasnikaInput) {
      napomena
    }
  }
`;

type PropTypes = {
  setTabela: any;
  tabela: any;
  vlasnik: any;
  izmeniVlasnika: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalForm: {
      width: 1200,
      margin: "auto"
    },
    button: {
      margin: theme.spacing(1)
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    root: {
      width: "100%",
      margin: "30px auto",
      paddingRight: 20,
      "& .MuiTextField-root": {
        margin: theme.spacing(1)
      }
    },
    title: {
      fontSize: 14,
      textAlign: "left"
    },
    dropdown: {
      width: "100%",
      paddingTop: 8
    },
    selectBox: {
      "& .MuiSelect-selectMenu": {
        padding: "10.5px 0px"
      }
    }
  })
);

export default function IzmeniVlasnika(props: PropTypes) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [trenutniPodaci, setTrenutniPodaci] = useState<any>({
    sifra: "",
    ime: "",
    prezime: "",
    mesto: "",
    fiksniTelefon: "",
    mobilniTelefon: "",
    postanskiBroj: "",
    kontaktMesto: "",
    adresa: "",
    gazdinstvoSifra: "",
    gazdinstvoNaziv: "",
    gazdinstvoStatus: "Aktivno",
    napomena: ""
  });
  const [buduciPodaci, setBuduciPodaci] = useState<any>({
    novaSifra: "",
    novoIme: "",
    novoPrezime: "",
    novoMesto: "",
    noviFiksniTelefon: "",
    noviMobilniTelefon: "",
    noviPostanskiBroj: "",
    novoKontaktMesto: "",
    novaAdresa: "",
    novoGazdinstvoSifra: "",
    novoGazdinstvoNaziv: "",
    novoGazdinstvoStatus: "Aktivno",
    novaNapomena: ""
  });

  function otvoriFormuZaIzmenuVlasnika(stariPodaci: any) {
    setTrenutniPodaci(stariPodaci);
    setBuduciPodaci({
      novaSifra: stariPodaci.sifra,
      novoIme: stariPodaci.ime,
      novoPrezime: stariPodaci.prezime,
      novoMesto: stariPodaci.mesto,
      noviFiksniTelefon: stariPodaci.fiksniTelefon,
      noviMobilniTelefon: stariPodaci.mobilniTelefon,
      noviPostanskiBroj: stariPodaci.postanskiBroj,
      novoKontaktMesto: stariPodaci.kontaktMesto,
      novaAdresa: stariPodaci.adresa,
      novoGazdinstvoSifra: stariPodaci.gazdinstvoSifra,
      novoGazdinstvoNaziv: stariPodaci.gazdinstvoNaziv,
      novoGazdinstvoStatus: stariPodaci.gazdinstvoStatus,
      novaNapomena: stariPodaci.napomena
    });
    setModalOpen(true);
  }

  function izmeniPodatkeVlasnika(event: React.FormEvent) {
    event.preventDefault();
    props.izmeniVlasnika({
      variables: {
        izmeniVlasnikaInput: { ...trenutniPodaci, ...buduciPodaci }
      }
    });
    const pripremiPodatkeZaZamenu = props.tabela.vlasnik.map(
      (vlasnik: ucitajVlasnike_vlasnik) => {
        if (vlasnik.sifra === trenutniPodaci.sifra) {
          return {
            _id: vlasnik._id,
            sifra: buduciPodaci.novaSifra,
            ime: buduciPodaci.novoIme,
            prezime: buduciPodaci.novoPrezime,
            mesto: buduciPodaci.novoMesto,
            fiksniTelefon: buduciPodaci.noviFiksniTelefon,
            mobilniTelefon: buduciPodaci.noviMobilniTelefon,
            postanskiBroj: buduciPodaci.noviPostanskiBroj,
            kontaktMesto: buduciPodaci.novoKontaktMesto,
            adresa: buduciPodaci.novaAdresa,
            gazdinstvoSifra: buduciPodaci.novoGazdinstvoSifra,
            gazdinstvoNaziv: buduciPodaci.novoGazdinstvoNaziv,
            gazdinstvoStatus: buduciPodaci.novoGazdinstvoStatus,
            napomena: buduciPodaci.novaNapomena
          };
        }
        return vlasnik;
      }
    );
    props.setTabela({
      vlasnik: pripremiPodatkeZaZamenu
    });
    setModalOpen(false);
    setTrenutniPodaci({
      sifra: "",
      ime: "",
      prezime: "",
      mesto: null,
      fiksniTelefon: null,
      mobilniTelefon: null,
      postanskiBroj: null,
      kontaktMesto: null,
      adresa: null,
      gazdinstvoSifra: null,
      gazdinstvoNaziv: null,
      gazdinstvoStatus: null,
      napomena: null
    });
  }

  return (
    <>
      <IconButton
        onClick={() => {
          otvoriFormuZaIzmenuVlasnika({
            sifra: props.vlasnik.sifra,
            ime: props.vlasnik.ime,
            prezime: props.vlasnik.prezime,
            mesto: props.vlasnik.mesto,
            fiksniTelefon: props.vlasnik.fiksniTelefon,
            mobilniTelefon: props.vlasnik.mobilniTelefon,
            postanskiBroj: props.vlasnik.postanskiBroj,
            kontaktMesto: props.vlasnik.kontaktMesto,
            adresa: props.vlasnik.adresa,
            gazdinstvoSifra: props.vlasnik.gazdinstvoSifra,
            gazdinstvoNaziv: props.vlasnik.gazdinstvoNaziv,
            gazdinstvoStatus: props.vlasnik.gazdinstvoStatus,
            napomena: props.vlasnik.napomena
          });
        }}
      >
        <EditIcon />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <form
              className={classes.modalForm}
              noValidate
              autoComplete="off"
              onSubmit={(e: React.FormEvent) => {
                izmeniPodatkeVlasnika(e);
              }}
            >
              <Card variant="outlined" className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Opsti podaci
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        id="novaSifra"
                        label="Interna Sifra"
                        variant="outlined"
                        value={buduciPodaci.novaSifra}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novaSifra: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        id="novoIme"
                        label="Ime"
                        variant="outlined"
                        value={buduciPodaci.novoIme}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoIme: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        fullWidth
                        required
                        id="novoPrezime"
                        label="Prezime"
                        variant="outlined"
                        value={buduciPodaci.novoPrezime}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoPrezime: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        fullWidth
                        id="novoMesto"
                        label="Mesto"
                        variant="outlined"
                        value={buduciPodaci.novoMesto}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoMesto: e.target.value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Kontakt
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="noviFiksniTelefon"
                        label="Fiksni telefon"
                        variant="outlined"
                        value={buduciPodaci.noviFiksniTelefon}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            noviFiksniTelefon: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="noviMobilniTelefon"
                        label="Mobilni telefon"
                        variant="outlined"
                        value={buduciPodaci.noviMobilniTelefon}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            noviMobilniTelefon: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="noviPostanskiBroj"
                        label="Postanski broj"
                        variant="outlined"
                        value={buduciPodaci.noviPostanskiBroj}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            noviPostanskiBroj: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="novoKontaktMesto"
                        label="Mesto"
                        variant="outlined"
                        value={buduciPodaci.novoKontaktMesto}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoKontaktMesto: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="novaAdresa"
                        label="Adresa"
                        variant="outlined"
                        value={buduciPodaci.novaAdresa}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novaAdresa: e.target.value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card variant="outlined" className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Gazdinstvo
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="novoGazdinstvoSifra"
                        label="Sifra gazdinstva"
                        variant="outlined"
                        value={buduciPodaci.novoGazdinstvoSifra}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoGazdinstvoSifra: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        id="novoGazdinstvoNaziv"
                        label="Naziv gazdinstva"
                        variant="outlined"
                        value={buduciPodaci.novoGazdinstvoNaziv}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novoGazdinstvoNaziv: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className={classes.dropdown}>
                        <Select
                          id="novoGazdinstvoStatus"
                          value={buduciPodaci.novoGazdinstvoStatus}
                          variant="outlined"
                          // @ts-ignore
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setBuduciPodaci({
                              ...buduciPodaci,
                              novoGazdinstvoStatus: e.target.value
                            });
                          }}
                          className={classes.selectBox}
                        >
                          <MenuItem value={"Aktivno"}>Aktivno</MenuItem>
                          <MenuItem value={"Neaktivno"}>Neaktivno</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        id="napomenaZaIzmenu"
                        label="Napomena"
                        variant="outlined"
                        multiline
                        value={buduciPodaci.novaNapomena}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setBuduciPodaci({
                            ...buduciPodaci,
                            novaNapomena: e.target.value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                disabled={
                  !(
                    buduciPodaci.novaSifra !== undefined &&
                    buduciPodaci.novoIme !== undefined &&
                    buduciPodaci.novoPrezime !== undefined
                  ) ||
                  !(
                    buduciPodaci.novaSifra.length !== 0 &&
                    buduciPodaci.novoIme.length !== 0 &&
                    buduciPodaci.novoPrezime.length !== 0
                  )
                }
              >
                Izmeni Vlasnika
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
