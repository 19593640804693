import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import gql from "graphql-tag";

export const DODAJ_OKRUG = gql`
  mutation dodajOkrug($naziv: String) {
    dodajOkrug(naziv: $naziv) {
      naziv
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      margin: "auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 600,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
  })
);

export default function DodajOkrug() {
  const resetOkruge = {
    naziv: "",
  };

  const classes = useStyles();
  const [dodajOkrug] = useMutation(DODAJ_OKRUG);
  const [buduciPodaci, setBuduciPodaci] = useState(resetOkruge);

  function dodajPodatkeOkruge(event: React.FormEvent) {
    event.preventDefault();
    dodajOkrug({
      variables: {
        naziv: buduciPodaci.naziv,
      },
      refetchQueries: ["ucitajOkruge"],
    });
    setBuduciPodaci(resetOkruge);
  }

  const onemogucenoDugme = buduciPodaci.naziv === "";

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        dodajPodatkeOkruge(e);
      }}
    >
      <div>
        <TextField
          required
          id="naziv"
          label="Naziv"
          variant="outlined"
          fullWidth
          value={buduciPodaci.naziv}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBuduciPodaci({ ...buduciPodaci, naziv: e.target.value })
          }
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
          disabled={onemogucenoDugme}
        >
          Dodaj Okrug
        </Button>
      </div>
    </form>
  );
}
