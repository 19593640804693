import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import NemaPodataka from "../NemaPodataka/NemaPodataka";

import DodajRasu from "./Rasa.dodaj";
import CitajRasu from "./Rasa.citaj";

import { UCITAJ_RASE } from "./Rasa.citaj";

export default function Rasa() {
  const { loading, error, data } = useQuery(UCITAJ_RASE);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <>
      <Typography variant="h6" noWrap>
        Rase svinja
      </Typography>
      <DodajRasu />
      {data.rasa.length === 0 ? (
        <NemaPodataka />
      ) : (
        <CitajRasu tabela={data.rasa} />
      )}
    </>
  );
}
