import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  PropTypes,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";

import gql from "graphql-tag";

export const DODAJ_VLASNIKA = gql`
  mutation dodajVlasnika($vlasnikInput: VlasnikInput) {
    dodajVlasnika(vlasnikInput: $vlasnikInput) {
      sifra
      ime
      prezime
      mesto
      fiksniTelefon
      mobilniTelefon
      postanskiBroj
      kontaktMesto
      adresa
      gazdinstvoSifra
      gazdinstvoNaziv
      gazdinstvoStatus
      napomena
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 1200,
      margin: "30px auto",
      paddingRight: 20,
      "& .MuiTextField-root": {
        margin: theme.spacing(1)
      }
    },
    button: {
      margin: theme.spacing(1)
    },
    title: {
      fontSize: 14,
      textAlign: "left"
    },
    dropdown: {
      width: "100%",
      paddingTop: 8
    },
    selectBox: {
      "& .MuiSelect-selectMenu": {
        padding: "10.5px 0px"
      }
    }
  })
);

type PropTypes = {
  dodajVlasnika: any;
  setPodaci: (event: any) => void;
  setTabela: any;
  podaci: any;
  tabela: any;
};

export default function DodajVlasnika(props: PropTypes) {
  const classes = useStyles();

  function dodajPodatkeVlasnike(event: React.FormEvent) {
    event.preventDefault();
    props.dodajVlasnika({
      variables: {
        vlasnikInput: props.podaci
      }
    });

    props.setTabela({
      vlasnik: [
        ...props.tabela.vlasnik,
        {
          ...props.podaci,
          _id: props.podaci.sifra
        }
      ]
    });
    props.setPodaci({
      sifra: "",
      ime: "",
      prezime: "",
      mesto: "",
      fiksniTelefon: "",
      mobilniTelefon: "",
      postanskiBroj: "",
      kontaktMesto: "",
      adresa: "",
      gazdinstvoSifra: "",
      gazdinstvoNaziv: "",
      gazdinstvoStatus: "Aktivno",
      napomena: ""
    });
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        dodajPodatkeVlasnike(e);
      }}
    >
      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Opsti podaci
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                required
                id="sifra"
                label="Interna Sifra"
                variant="outlined"
                value={props.podaci.sifra === null ? "" : props.podaci.sifra}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    sifra: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                required
                id="ime"
                label="Ime"
                variant="outlined"
                value={props.podaci.ime === null ? "" : props.podaci.ime}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({ ...props.podaci, ime: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                required
                id="prezime"
                label="Prezime"
                variant="outlined"
                value={
                  props.podaci.prezime === null ? "" : props.podaci.prezime
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({ ...props.podaci, prezime: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                id="mesto"
                label="Mesto"
                variant="outlined"
                value={props.podaci.mesto === null ? "" : props.podaci.mesto}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({ ...props.podaci, mesto: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Kontakt
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="fiksniTelefon"
                label="Fiksni telefon"
                variant="outlined"
                value={
                  props.podaci.fiksniTelefon === null
                    ? ""
                    : props.podaci.fiksniTelefon
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    fiksniTelefon: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="mobilniTelefon"
                label="Mobilni telefon"
                variant="outlined"
                value={
                  props.podaci.mobilniTelefon === null
                    ? ""
                    : props.podaci.mobilniTelefon
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    mobilniTelefon: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="postanskiBroj"
                label="Postanski broj"
                variant="outlined"
                value={
                  props.podaci.postanskiBroj === null
                    ? ""
                    : props.podaci.postanskiBroj
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    postanskiBroj: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="kontaktMesto"
                label="Mesto"
                variant="outlined"
                value={
                  props.podaci.kontaktMesto === null
                    ? ""
                    : props.podaci.kontaktMesto
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    kontaktMesto: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="adresa"
                label="Adresa"
                variant="outlined"
                value={props.podaci.adresa === null ? "" : props.podaci.adresa}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({ ...props.podaci, adresa: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Gazdinstvo
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="gazdinstvoSifra"
                label="Sifra gazdinstva"
                variant="outlined"
                value={
                  props.podaci.gazdinstvoSifra === null
                    ? ""
                    : props.podaci.gazdinstvoSifra
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    gazdinstvoSifra: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                id="gazdinstvoNaziv"
                label="Naziv gazdinstva"
                variant="outlined"
                value={
                  props.podaci.gazdinstvoNaziv === null
                    ? ""
                    : props.podaci.gazdinstvoNaziv
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    gazdinstvoNaziv: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.dropdown}>
                <Select
                  id="gazdinstvoStatus"
                  value={
                    props.podaci.gazdinstvoStatus === null
                      ? ""
                      : props.podaci.gazdinstvoStatus
                  }
                  variant="outlined"
                  // @ts-ignore
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.setPodaci({
                      ...props.podaci,
                      gazdinstvoStatus: e.target.value
                    });
                  }}
                  className={classes.selectBox}
                >
                  <MenuItem value={"Aktivno"}>Aktivno</MenuItem>
                  <MenuItem value={"Neaktivno"}>Neaktivno</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                id="napomena"
                label="Napomena"
                variant="outlined"
                multiline
                value={
                  props.podaci.napomena === null ? "" : props.podaci.napomena
                }
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.setPodaci({
                    ...props.podaci,
                    napomena: e.target.value
                  })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        type="submit"
        disabled={
          !(
            props.podaci.sifra !== undefined &&
            props.podaci.ime !== undefined &&
            props.podaci.prezime !== undefined
          ) ||
          !(
            props.podaci.sifra.length !== 0 &&
            props.podaci.ime.length !== 0 &&
            props.podaci.prezime.length !== 0
          )
        }
      >
        Dodaj Vlasnika
      </Button>
    </form>
  );
}
