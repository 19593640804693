import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IzmeniKrmacu from "./Krmaca.izmeni";
import BrisiKrmacu from "./Krmaca.brisi";
import gql from "graphql-tag";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ucitajKrmace_krmaca } from "../../generated/ucitajKrmace";

export const UCITAJ_KRMACE_FINAL = gql`
  query ucitajKrmace {
    krmaca {
      _id
      idKrmace
      mbr
      selekcijskiBroj
      hbrb
      rasa {
        _id
      }
      opis
      datumRodjenja
      iskljucenje
      uzrok
      ocena {
        _id
        opis
      }
      okrug {
        _id
        naziv
      }
      odgajivac {
        _id
        ime
        prezime
      }
      vlasnik {
        _id
        ime
        prezime
      }
      napomena
      otac {
        _id
        idKrmace
        mbr
        selekcijskiBroj
        otac {
          _id
          idKrmace
          mbr
          selekcijskiBroj
        }
      }
      majka {
        _id
        idKrmace
        mbr
        selekcijskiBroj
        otac {
          _id
          idKrmace
          mbr
          selekcijskiBroj
        }
      }
    }
  }
`;

export const UCITAJ_KRMACE = gql`
  query ucitajKrmace {
    krmaca {
      _id
      mbr
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 600,
      margin: "30px auto",
    },
  })
);

type PropTypes = {
  tabela: ucitajKrmace_krmaca[];
};

// TODO: Dodaj sva polja
export default function CitajKrmacu(props: PropTypes) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>_id</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tabela.map((krmaca: ucitajKrmace_krmaca) => {
            return (
              <TableRow key={krmaca._id}>
                <TableCell>{krmaca._id}</TableCell>
                <TableCell align="right">
                  <IzmeniKrmacu zaIzmenu={krmaca} />
                  <BrisiKrmacu id={krmaca._id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
