import { combineReducers } from "redux";
import { SET_CURRENT_USER } from "../actions";

const INITIAL_STATE = {
  currentUser: {
    sifra: "",
    _id: "",
  },
};

function currentUser(state = INITIAL_STATE.currentUser, action: any) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.payload.currentUser;
    default:
      return state;
  }
}

export default combineReducers({
  currentUser,
});
