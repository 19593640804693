import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import NemaPodataka from "../NemaPodataka/NemaPodataka";

import DodajOcenu from "./Ocena.dodaj";
import CitajOcenu from "./Ocena.citaj";

import { UCITAJ_OCENE } from "./Ocena.citaj";

export default function Ocena() {
  const { loading, error, data } = useQuery(UCITAJ_OCENE);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <>
      <Typography variant="h6" noWrap>
        Ocene svinja
      </Typography>
      <DodajOcenu />
      {data.ocena.length === 0 ? (
        <NemaPodataka />
      ) : (
        <CitajOcenu tabela={data.ocena} />
      )}
    </>
  );
}
