import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Modal,
  Backdrop,
  Fade,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import EditIcon from "@material-ui/icons/Edit";
import { ucitajOcene_ocena } from "../../generated/ucitajOcene";

export const IZMENI_OCENU = gql`
  mutation izmeniOcenu($izmeniOcenuInput: IzmeniOcenuInput) {
    izmeniOcenu(izmeniOcenuInput: $izmeniOcenuInput) {
      napomena
    }
  }
`;

type PropTypes = {
  zaIzmenu: ucitajOcene_ocena;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalForm: {
      width: 400,
      margin: "auto",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 400,
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function IzmeniOcenu(props: PropTypes) {
  const resetOcene = {
    opis: props.zaIzmenu.opis,
    napomena: props.zaIzmenu.napomena,
  };
  const classes = useStyles();

  const [izmeniOcenu] = useMutation(IZMENI_OCENU);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [buduciPodaci, setBuduciPodaci] = useState(resetOcene);

  function otvoriFormuZaIzmenuOcene() {
    setModalOpen(true);
  }

  function izmeniPodatkeOcene(event: React.FormEvent) {
    event.preventDefault();
    izmeniOcenu({
      variables: {
        izmeniOcenuInput: { _id: props.zaIzmenu._id, ...buduciPodaci },
      },
      refetchQueries: ["ucitajOcene"],
    });
    setModalOpen(false);
  }

  const onemogucenoDugme = buduciPodaci.opis === "";

  return (
    <>
      <IconButton
        onClick={() => {
          otvoriFormuZaIzmenuOcene();
        }}
      >
        <EditIcon />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <form
              className={classes.modalForm}
              noValidate
              autoComplete="off"
              onSubmit={(e: React.FormEvent) => {
                izmeniPodatkeOcene(e);
              }}
            >
              <div>
                <TextField
                  required
                  id="opisZaIzmenu"
                  label="Opis"
                  variant="outlined"
                  value={buduciPodaci.opis}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setBuduciPodaci({
                      ...buduciPodaci,
                      opis: e.target.value,
                    })
                  }
                />
                <TextField
                  id="napomenaZaIzmenu"
                  label="Napomena"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="4"
                  value={buduciPodaci.napomena}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setBuduciPodaci({
                      ...buduciPodaci,
                      napomena: e.target.value,
                    })
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  disabled={onemogucenoDugme}
                >
                  Izmeni Ocenu
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
