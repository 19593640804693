import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import NemaPodataka from "../NemaPodataka/NemaPodataka";

import DodajOkrug from "./Okrug.dodaj";
import CitajOkrug from "./Okrug.citaj";

import { UCITAJ_OKRUGE } from "./Okrug.citaj";

export default function Okrug() {
  const { loading, error, data } = useQuery(UCITAJ_OKRUGE);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error.message} />;
  }

  return (
    <>
      <Typography variant="h6" noWrap>
        Okruzi
      </Typography>
      <DodajOkrug />
      {data.okrug.length === 0 ? (
        <NemaPodataka />
      ) : (
        <CitajOkrug tabela={data.okrug} />
      )}
    </>
  );
}
